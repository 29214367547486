import { getEntryPointsFirestore } from 'admin/modules/entry-points/hooks/useEntryPointsQuery';
import { FirebaseContext, UserContext } from 'contexts';
import { getEventFirestore } from 'firebase/events';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Loading } from 'pwa/components';
import { notification } from 'antd';
import {
  CheckInDrawer,
  CheckInSuccessDrawer,
  CheckOutDrawer,
  CheckOutSuccessDrawer,
  NotScheduledDrawer,
  TryAgainDrawer,
} from './CheckDrawer';
import { Scanner } from './Scanner';

// const eventParticipantModel = {
//   userId: '123',
//   eventId: '456',
//   companyId: '789',
//   checkInTime: null,
//   checkOutTime: null,
//   checkInLocation: null,
//   checkOutLocation: null,
//   didCheckIn: false,
//   didCheckOut: false,
// };

export async function getEventParticipantFirestore(firebase, eventId, userId) {
  const eventParticipant = await firebase
    .firestore()
    .collection('eventParticipants')
    .where('eventId', '==', eventId)
    .where('userId', '==', userId)
    .get();
  return eventParticipant.docs.map((doc) => doc.data())?.[0];
}

async function createEventParticipantFirestore(firebase, eventParticipant) {
  const eventParticipantRef = firebase
    .firestore()
    .collection('eventParticipants')
    .doc(); // Generate a random document ID

  await eventParticipantRef.set({
    ...eventParticipant,
    id: eventParticipantRef.id,
  });
}

async function updateEventParticipantFirestore(
  firebase,
  eventParticipantId,
  propertiesToUpdate,
) {
  await firebase
    .firestore()
    .collection('eventParticipants')
    .doc(eventParticipantId)
    .update(propertiesToUpdate);
}

const drawerNames = {
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  checkInSuccess: 'checkInSuccess',
  checkOutSuccess: 'checkOutSuccess',
  notScheduled: 'notScheduled',
  tryAgain: 'tryAgain',
};

export function ScannerPage() {
  const firebase = useContext(FirebaseContext);
  const router = useRouter();
  const { eventId } = router.query;
  const { user } = useContext(UserContext);

  const [event, setEvent] = useState(null);
  const [entryPoints, setEntryPoints] = useState(null);
  const [currentDrawer, setCurrentDrawer] = useState(null); // Holds current open drawer name
  const [qrScanResult, setQrScanResult] = useState(null);
  const [eventParticipant, setEventParticipant] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!eventId) {
      router.push('/app');
    } else {
      getEvent();
    }
  }, [eventId]);

  useEffect(() => {
    if (currentDrawer) return;

    if (qrScanResult) {
      const isWorkerAccepted = event?.acceptedStaff.includes(user.id);
      if (!isWorkerAccepted) {
        openDrawer(drawerNames.notScheduled);
        return;
      }
      const entryPoint = entryPoints?.find(
        (point) => point.id === qrScanResult,
      );
      const hasEventEntryPoint = event?.selectedEntryPointIds?.includes(
        qrScanResult,
      );
      const isValid = entryPoint && hasEventEntryPoint;
      if (!isValid) {
        openDrawer(drawerNames.tryAgain);
        return;
      }

      if (eventParticipant?.didCheckIn && !eventParticipant?.didCheckOut) {
        openDrawer(drawerNames.checkOut);
      } else {
        openDrawer(drawerNames.checkIn);
      }
    }
  }, [qrScanResult]);

  async function getEvent() {
    try {
      const eventPromise = getEventFirestore(firebase, eventId);
      const participantPromise = getEventParticipantFirestore(
        firebase,
        eventId,
        user.id,
      );
      const fetchedEvent = await eventPromise;
      const entryPointsPromise = getEntryPointsFirestore(
        firebase,
        fetchedEvent.companyId,
      );
      const [fetchedEntryPoints, fetchedEventParticipant] = await Promise.all([
        entryPointsPromise,
        participantPromise,
      ]);
      setEvent(fetchedEvent);
      setEntryPoints(fetchedEntryPoints);
      setEventParticipant(fetchedEventParticipant);
      setLoading(false);
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the event. Please try again later.',
      });
      console.error(error);
      router.push('/app');
    }
  }

  function goBackToEvents() {
    router.push('/app');
  }

  function openDrawer(drawerName) {
    setCurrentDrawer(drawerName);
  }

  function closeDrawers() {
    setCurrentDrawer(null);
  }

  function handleQrResult(result) {
    if (result && result.text !== qrScanResult) {
      setQrScanResult(result.text);
    }
  }

  async function handleCheckInClick() {
    const eventParticipantData = {
      userId: user.id,
      eventId: event.eventId,
      companyId: event.companyId,
      checkInTime: new Date(),
      checkInLocation: qrScanResult,
      didCheckIn: true,
    };
    if (eventParticipant) {
      await updateEventParticipantFirestore(
        firebase,
        eventParticipant.id,
        eventParticipantData,
      );
    } else {
      await createEventParticipantFirestore(firebase, eventParticipantData);
    }
    setEventParticipant(eventParticipantData);
    openDrawer(drawerNames.checkInSuccess);
  }

  async function handleCheckOutClick() {
    const eventParticipantData = {
      checkOutTime: new Date(),
      checkOutLocation: qrScanResult,
      didCheckOut: true,
    };
    await updateEventParticipantFirestore(
      firebase,
      eventParticipant.id,
      eventParticipantData,
    );
    openDrawer(drawerNames.checkOutSuccess);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Scanner onClose={goBackToEvents} onResult={handleQrResult} />
      <CheckInDrawer
        open={currentDrawer === drawerNames.checkIn}
        event={event}
        onOk={handleCheckInClick}
        onCancel={goBackToEvents}
      />
      <CheckOutDrawer
        open={currentDrawer === drawerNames.checkOut}
        event={event}
        onOk={handleCheckOutClick}
        onCancel={goBackToEvents}
      />
      <CheckInSuccessDrawer
        open={currentDrawer === drawerNames.checkInSuccess}
        event={event}
        onClick={goBackToEvents}
      />
      <CheckOutSuccessDrawer
        open={currentDrawer === drawerNames.checkOutSuccess}
        event={event}
        onClick={goBackToEvents}
      />
      <NotScheduledDrawer
        open={currentDrawer === drawerNames.notScheduled}
        onClick={goBackToEvents}
      />
      <TryAgainDrawer
        open={currentDrawer === drawerNames.tryAgain}
        onOk={closeDrawers}
        onCancel={goBackToEvents}
      />
    </div>
  );
}
