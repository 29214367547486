import { notification } from 'antd';
import { useEffect, useState } from 'react';

export async function getEntryPointsFirestore(firebase, companyId) {
  const db = firebase.firestore();
  const fetchedEntryPoints = await db
    .collection('entryPoints')
    .where('companyId', '==', companyId)
    .get();
  const entryPoints = fetchedEntryPoints.docs.map((doc) => doc.data());
  return entryPoints;
}

export function useEntryPointsQuery(firebase, companyId) {
  const [entryPoints, setEntryPoints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const fetchedEntryPoints = await getEntryPointsFirestore(
          firebase,
          companyId,
        );
        // Sort entry points by dateCreated (most recent last)
        fetchedEntryPoints.sort((a, b) => a.dateCreated - b.dateCreated);
        setEntryPoints(fetchedEntryPoints);
      } catch (error) {
        console.error('error fetching entry points', error);
        notification.error({
          message: 'Error fetching entry points',
          description: 'Please try again later',
        });
      } finally {
        setLoading(false);
      }
    }
    if (!companyId) {
      return;
    }
    getData();
  }, [firebase, companyId]);

  return { entryPoints, setEntryPoints, loading };
}
