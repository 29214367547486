import {
  CloseOutlined,
  ExclamationOutlined,
  LoginOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import styles from './CheckDrawer.module.scss';
import dayjs from 'dayjs';

export function getLocationString(eventLocation) {
  if (!eventLocation) return '';

  const parts = [
    eventLocation.place,
    eventLocation.city,
    eventLocation.country,
  ].filter(Boolean);

  return parts.join(', ');
}

export function RoundDrawer({ open, children, height = 463 }) {
  return (
    <Drawer
      visible={open}
      placement="bottom"
      closable={false}
      className={styles.roundDrawer}
      height={height}
    >
      {children}
    </Drawer>
  );
}

function EventImage({ eventImageUrl }) {
  const eventImageSource = eventImageUrl ?? '/images/event-placeholder.svg';

  const eventImageClass = eventImageUrl
    ? `${styles.eventImage}`
    : `${styles.imagePlaceholder}`;

  return (
    <div className={styles.eventImageContainer}>
      <img
        src={eventImageSource}
        className={eventImageClass}
        alt="Event image placeholder"
      />
    </div>
  );
}

function YellowButton({ children, onClick }) {
  return (
    <Button className={styles.yellowButton} onClick={onClick}>
      {children}
    </Button>
  );
}

function BlueButton({ children, onClick }) {
  return (
    <Button type="link" className={styles.blueButton} onClick={onClick}>
      {children}
    </Button>
  );
}

function EventText({ event }) {
  return (
    <div className={styles.eventText}>
      <div className={styles.eventName}>{event?.eventName}</div>
      <div className={styles.eventLocation}>
        {getLocationString(event?.eventLocation)}
      </div>
      <div className={styles.eventDate}>
        {dayjs(event?.eventDate?.toDate()).format(
          'dddd, D MMMM YYYY [at] h:mm',
        )}
      </div>
    </div>
  );
}

export function CheckInDrawer({ event, open, onOk, onCancel }) {
  return (
    <RoundDrawer open={open}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.checkTitle}>You are checking IN for:</div>
        <div className={styles.eventInfo}>
          <EventText event={event} />
          <EventImage eventImageUrl={event?.eventImage} />
        </div>
        <YellowButton onClick={onOk}>
          <span>
            Check <u>IN</u> to event
          </span>
        </YellowButton>
        <BlueButton onClick={onCancel}>CANCEL</BlueButton>
      </div>
    </RoundDrawer>
  );
}

export function CheckOutDrawer({ event, open, onOk, onCancel }) {
  return (
    <RoundDrawer open={open}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.checkTitle}>You are checking OUT of:</div>
        <div className={styles.eventInfo}>
          <EventText event={event} />
          <EventImage eventImageUrl={event?.eventImage} />
        </div>
        <YellowButton onClick={onOk}>
          <span>
            Check <u>Out</u> of event
          </span>
        </YellowButton>
        <BlueButton onClick={onCancel}>CANCEL</BlueButton>
      </div>
    </RoundDrawer>
  );
}

function RoundIcon({ children, className }) {
  return <div className={`${styles.roundIcon} ${className}`}>{children}</div>;
}

export function CheckInSuccessDrawer({ open, event, onClick }) {
  return (
    <RoundDrawer open={open} height={315}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.titleAndIcon}>
          <RoundIcon className={styles.checkInIcon}>
            <LoginOutlined />
          </RoundIcon>
          <div className={styles.checkTitle}>You are now checked IN for:</div>
        </div>
        <EventText event={event} />
        <BlueButton onClick={onClick}>BACK TO EVENTS</BlueButton>
      </div>
    </RoundDrawer>
  );
}

export function CheckOutSuccessDrawer({ open, event, onClick }) {
  return (
    <RoundDrawer open={open} height={315}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.titleAndIcon}>
          <RoundIcon className={styles.checkOutIcon}>
            <LogoutOutlined />
          </RoundIcon>
          <div className={styles.checkTitle}>You are now checked OUT for:</div>
        </div>
        <EventText event={event} />
        <BlueButton onClick={onClick}>DISMISS</BlueButton>
      </div>
    </RoundDrawer>
  );
}

export function NotScheduledDrawer({ open, onClick }) {
  return (
    <RoundDrawer open={open} height={315}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.titleAndIcon}>
          <RoundIcon className={styles.notScheduledIcon}>
            <CloseOutlined />
          </RoundIcon>
          <div className={styles.checkTitle}>
            You are not scheduled <br /> for this event
          </div>
        </div>
        <BlueButton onClick={onClick}>DISMISS</BlueButton>
      </div>
    </RoundDrawer>
  );
}

export function TryAgainDrawer({ open, onOk, onCancel }) {
  return (
    <RoundDrawer open={open} height={315}>
      <div className={styles.checkDrawerContent}>
        <div className={styles.titleAndIcon}>
          <RoundIcon className={styles.errorIcon}>
            <ExclamationOutlined />
          </RoundIcon>
          <div className={styles.checkTitle}>
            We were unable to verify the QR, please try again
          </div>
        </div>
        <YellowButton onClick={onOk}>Try again</YellowButton>
        <BlueButton onClick={onCancel}>DISMISS</BlueButton>
      </div>
    </RoundDrawer>
  );
}
