import { CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import styles from './Scanner.module.scss';

export function Scanner({ onClose, onResult }) {
  return (
    <div className={styles.background}>
      <div className={styles.header}>
        <CameraTutorial />

        <CloseButton onClick={onClose} />
      </div>
      <div className={styles.qrReaderContainer}>
        <QrReader
          onResult={onResult}
          className={styles.qrReaderClass}
          constraints={{ facingMode: 'environment' }}
        />
      </div>
    </div>
  );
}

function CameraTutorial() {
  // result state can be 'granted', 'denied', 'prompt'
  const [resultState, setResultState] = useState(null);

  useEffect(() => {
    async function checkCameraPermissions() {
      const result = await navigator.permissions.query({ name: 'camera' });
      setResultState(result.state);
    }
    // I have tried to use result.onchange event listener, but it didn't work on safari
    // so I decided to use setInterval to check the camera permissions every 500ms
    // Additionally the result.state is still 'prompt'
    // even after the user has cancelled the permission prompt
    checkCameraPermissions();
    const intervalId = setInterval(checkCameraPermissions, 500);
    return () => clearInterval(intervalId);
  }, []);

  // Doesn't work on safari because the result.state is always 'prompt' when cancelled

  // if (resultState === null || resultState === 'prompt') {
  //   // While we are checking, show this message
  //   return <div>Checking permissions...</div>;
  // }

  if (resultState === 'granted') {
    return <div>Get close to the QR code in order to scan it.</div>;
  }

  return (
    <div className={styles.cameraPermissionsTutorial}>
      Camera access has not been granted.
      <br />
      To scan QR codes, please enable camera permissions in your browser
      settings and/or reload the page.
    </div>
  );
}

function CloseButton({ onClick }) {
  return (
    <button className={styles.closeButton} type="button" onClick={onClick}>
      <CloseOutlined className={styles.closeButtonIcon} />
    </button>
  );
}
