// Process for company with staffing package:

// Step 1: Invitation
// [Admin invites worker] -> worker is added to recruitedStaff array

// Step 2: Availability Confirmation
// Worker indicates availability.
// [Worker clicks available] -> worker is added to available array
// [worker clicks unavailable] -> worker is added to unavailable array

// Step 3: Admin Action on Available Workers
// If the worker is available, the admin has two options:
// [Admin clicks Accept] -> worker is added to acceptedStaff and removed from available array
// [Admin clicks Reject] -> worker is added to rejectedStaff and removed from available array

export async function getEventFirestore(firebase, eventId) {
  const db = firebase.firestore();
  const eventSnapshot = await db.collection('events').doc(eventId).get();
  if (eventSnapshot.exists) {
    return eventSnapshot.data();
  } else {
    throw new Error('Could not get event data from firestore');
  }
}

export async function getEventsWithWorkerFirestore(firebase, workerId) {
  // do not use this function if events need to be from current company
  const db = firebase.firestore();
  const eventsWithWorker = await db
    .collection('events')
    .where('recruitedStaff', 'array-contains', workerId)
    .get();

  return eventsWithWorker.docs.map((event) => event.data());
}

export async function getEventsByCompanyIdFirestore(firebase, companyId) {
  const db = firebase.firestore();
  const eventsSnaphot = await db
    .collection('events')
    .where('companyId', '==', companyId)
    .get();
  const eventsData = eventsSnaphot.docs.map((doc) => doc.data());
  return eventsData ?? [];
}

export async function publishEventFirestore(firebase, eventId) {
  const ref = firebase.firestore().collection('events').doc(eventId);
  await ref.update({
    status: 'published',
  });
}

export async function archiveEventFirestore(firebase, eventId) {
  // TODO: create a isActive flag and add it to queries.
  // We have agreed to add it during the migration
  const db = firebase.firestore();
  const eventRef = db.collection('events').doc(eventId);
  const eventSnapshot = await eventRef.get();
  if (!eventSnapshot.exists) {
    throw new Error('Event document does not exist!');
  }
  const eventData = eventSnapshot.data();
  const archiveRef = db.collection('archivedEvents').doc(eventId);

  // delete the event doc, and copy it to archivedEvents
  const batch = db.batch();
  batch.delete(eventRef);
  batch.set(archiveRef, eventData);
  await batch.commit();
}

async function eventStaffingTransaction(
  transaction,
  firebase,
  eventRef,
  addedStaff,
  deletedStaff,
  currentAcceptedStaff,
  currentRejectedStaff,
) {
  // This code may get re-run multiple times if there are conflicts.
  const eventDoc = await transaction.get(eventRef);
  if (!eventDoc.exists) {
    throw new Error('Event document does not exist!');
  }
  const eventData = eventDoc.data();
  const databaseAvailableStaff = eventData?.available;

  // only accept/reject staff that are available
  // (might have been deleted or accepted/rejected already)
  const acceptedStaffToAdd = currentAcceptedStaff.filter((acceptedStaffId) => {
    return databaseAvailableStaff.includes(acceptedStaffId);
  });
  const rejectedStaffToAdd = currentRejectedStaff.filter((rejectedStaffId) => {
    return databaseAvailableStaff.includes(rejectedStaffId);
  });
  // remove accepted/rejected from available
  const availableStaffToRemove = [...acceptedStaffToAdd, ...rejectedStaffToAdd];

  const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;

  transaction.update(eventRef, {
    recruitedStaff: arrayUnion(...addedStaff),
    acceptedStaff: arrayUnion(...acceptedStaffToAdd),
    rejectedStaff: arrayUnion(...rejectedStaffToAdd),
    available: arrayRemove(...availableStaffToRemove),
  });
  // delete deletedStaff
  transaction.update(eventRef, {
    recruitedStaff: arrayRemove(...deletedStaff),
    acceptedStaff: arrayRemove(...deletedStaff),
    rejectedStaff: arrayRemove(...deletedStaff),
    available: arrayRemove(...deletedStaff),
    unAvailable: arrayRemove(...deletedStaff),
  });
}

/*
Types of operations that can happen and how this impacts database:

1. Admin adds worker to event:
- worker needs to be added to recruitedStaff
If other admin added him already it's not a problem since we are using ArrayUnion(),
which handles the duplicates for us.

2. Admin change the worker status:
- worker needs to be added to acceptedStaff OR rejectedStaff
- worker needs to be removed from availableStaff
Danger! this worker might have been deleted or accepted/rejected by another admin.
- use a transaction to check if he is still in availableArray if yes proceed.
if not it means worker  status have changed and admin don't have permission to edit him.

3. Admin deletes worker from event
- worker needs to be removed from every array.
If other admin removed him already it's not a problem since we are using ArrayRemove(),
which handles the duplicates for us

Make all the operations atomic
*/
export async function saveEventStaffingFirestore(
  firebase,
  eventId,
  initialStaffingData,
  currentRecruitedStaff,
  currentAcceptedStaff,
  currentRejectedStaff,
) {
  const db = firebase.firestore();

  const eventRef = db.collection('events').doc(eventId);

  const { deletedStaff, addedStaff } = compareRecruitedStaff(
    initialStaffingData,
    currentRecruitedStaff,
  );

  await db.runTransaction((transaction) =>
    eventStaffingTransaction(
      transaction,
      firebase,
      eventRef,
      addedStaff,
      deletedStaff,
      currentAcceptedStaff,
      currentRejectedStaff,
    ),
  );
}

function compareRecruitedStaff(initialRecruited, currentRecruited) {
  // if in initial but not in current means user was deleted
  const deletedStaff = initialRecruited.filter(
    (userId) => !currentRecruited.includes(userId),
  );

  // if in current but not in initial means user was added
  const addedStaff = currentRecruited.filter(
    (userId) => !initialRecruited.includes(userId),
  );

  return { deletedStaff, addedStaff };
}
